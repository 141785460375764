import { useEffect } from 'react';

function FormPage() {

  // The code below will load the embed
	useEffect(() => {
    console.log("prueba effect")
		const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

    const load = () => {
      // Load Tally embeds
      if (typeof window.Tally !== 'undefined') {
        window.Tally?.loadEmbeds();
        return;
      }

      // Fallback if window.Tally is not available
      document
        .querySelectorAll('iframe[data-tally-src]:not([src])')
        .forEach((iframeEl) => {
          iframeEl.src = iframeEl.dataset.tallySrc;
        });
    };

    // If Tally is already loaded, load the embeds
    if (typeof window.Tally !== 'undefined') {
      load();
      return;
    }

    // If the Tally widget script is not loaded yet, load it
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement('script');
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
      return;
    }
  }, []);
  
    return (
      <div class="form-page font-avenir">
        <h2>
        ¿TIENES UNO O VARIOS PISOS TURÍSTICOS EN TU BLOQUE?
        </h2>
        <p >
        Si a ti también te preocupa, esto te interesa
        </p>
        <p >
        En los últimos años se ha multiplicado el número de pisos turísticos. Sin embargo, la mayoría son ilegales en Cantabria. Esto permite que puedes actuar contra ellos. Estamos trabajando en identificar todos los pisos turísticos con o sin licencia, ¿Cónoces algún piso turístico en tu zona? ¡Cuéntanoslo para que podamos ampliar el mapeo y denunciarlo!
        </p>

        <iframe data-tally-src="https://tally.so/embed/me22KJ?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1" 
          loading="lazy" width="100%" height="428"
          frameBorder="0" marginHeight="0" marginWidth="0"
          title="¿Pisos turísticos SIN registrar? ¡Cuéntanoslo!"
          ></iframe>
      </div>
    );
  }
  
  export default FormPage;