function HomePage() {
    return (
      <div class="home">
        <h1>CANTABRIA PARA VIVIR.</h1>
        <h2 class="font-avenir">Frente al modelo impuesto nos organizamos para parar la turistificación de Cantabria y defender el derecho a la vivienda</h2>
      </div>
    );
  }
  
  export default HomePage;
  