function MapPage() {
    return (
      <div>
        <iframe 
            src="https://pisosturisticosmapacantabria.shinyapps.io/Mapa_pisos_turisticos/"
            title="Mapa Viviendas turisticas Santander"
            >
        </iframe>
      </div>
    );
  }
  
  export default MapPage;